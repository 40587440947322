/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:36c112f3-3c8b-44ec-9ca7-5b8b54b47a68",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HBsN4bpSO",
    "aws_user_pools_web_client_id": "kpnsvkdh6dq0u83crf4927dcb",
    "oauth": {},
    "predictions": {
        "interpret": {
            "interpretText": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "type": "ALL"
                }
            }
        }
    }
};


export default awsmobile;
