import React, {useState} from 'react';
import './App.css';
import Amplify, {Predictions} from 'aws-amplify';
import aws_exports from './aws-exports';
import {AmazonAIPredictionsProvider} from '@aws-amplify/predictions';
import {withAuthenticator, AmplifySignOut} from '@aws-amplify/ui-react';

Amplify.configure(aws_exports);
Amplify.addPluggable(new AmazonAIPredictionsProvider());

function App() {
    const [response, setResponse] = useState("Write some text above and get sentiment")
    const [textToInterpret, setTextToInterpret] = useState("");
    const interpret = async () => {
        const result = await Predictions.interpret({
            text: {
                source: {
                    text: textToInterpret
                }
            }
        })
        setResponse(JSON.stringify(result.textInterpretation.sentiment, null, 2))
    }

    function setText(event) {
        setTextToInterpret(event.target.value);
    }

    return (
        <div align="center" style={{backgroundColor: "lightblue"}}>
            <AmplifySignOut/>
            <div>
                <h3>Text interpretation</h3>
                <input value={textToInterpret} onChange={setText}/>
                <button onClick={interpret}>Get Sentiment</button>
                <p>{response}</p>
            </div>
        </div>
    )
        ;
}

export default withAuthenticator(App, true);
